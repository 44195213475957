import {observer} from 'mobx-react';
import React from 'react';

import autobind from 'common/decorators/autobind.js';
import Button from 'core/components/Button.js';
import Icon from 'core/components/Icon.js';
import {PromotionsStoreContext} from 'core/stores/RootStore.js';
import {MODAL_TYPE_PROMOTION} from 'promotions/stores/PromotionsStore.js';

export default
@observer
class TeledocOptions extends React.Component {
    static contextType = PromotionsStoreContext;
    eventLabel = 'teladoc-tds';

    @autobind
    handleButton() {
        this.context.showModal(MODAL_TYPE_PROMOTION);
    }

    render() {
        return (
            <div className="offers-section">
                <h2>
                    {
                        'Hip, spine (back), shoulder or knee pain? Facing possible surgery?'
                    }
                </h2>
                <p>
                    {
                        'Bupa members have free access to independent advice on treatment options from leading orthopaedic surgeons.'
                    }
                </p>
                <Button
                    action={this.handleButton}
                    customClass="cta tertiary offers"
                    text={
                        <>
                            <strong>{'Learn more'}</strong>
                            <Icon name={'bookmark'} />
                        </>
                    }
                />
            </div>
        );
    }
}
