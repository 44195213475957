import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import autobind from 'common/decorators/autobind.js';
import Modal from 'core/components/Modal.js';
import PromotionsContent from 'promotions/components/PromotionsContent.js';
import {PromotionsStoreContext} from 'core/stores/RootStore.js';

export default
@observer
class PromotionsApp extends React.Component {
    static contextType = PromotionsStoreContext;

    static propTypes = {
        closePreviousModal: PropTypes.func.isRequired,
        isEnhanced: PropTypes.bool.isRequired,
        practices: PropTypes.arrayOf(
            PropTypes.shape({
                localityId: PropTypes.number.isRequired,
            }),
        ),
        promotions: PropTypes.arrayOf(
            PropTypes.shape({
                healthFunds: PropTypes.arrayOf(PropTypes.number).isRequired,
                specialties: PropTypes.arrayOf(PropTypes.number).isRequired,
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
            }),
        ),
        showPreviousModal: PropTypes.func.isRequired,
        specialInterests: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                specialty: PropTypes.number.isRequired,
            }),
        ).isRequired,
        specialties: PropTypes.arrayOf(
            PropTypes.shape({
                displayName: PropTypes.string.isRequired,
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
                order: PropTypes.number,
            }),
        ),
    };

    componentDidMount() {
        const {
            isEnhanced,
            practices,
            promotions,
            specialInterests,
            specialties,
        } = this.props;
        this.context.updateStore({
            isEnhanced,
            practices,
            promotions,
            specialInterests,
            specialties,
        });
        if (specialties.length === 1) {
            this.context.updateStore({selectedSpecialty: specialties[0].id});
        } else {
            this.context.updateStore({bannerAdsFetched: true});
        }
    }

    componentDidUpdate() {
        const {
            rootStore: {
                healthFundStore: {healthFund},
            },
        } = this.context;
        if (healthFund) {
            this.context.setHasNoPromotions();
        }
    }

    renderModal() {
        const {closeModal, isMobile, isMobileOrTablet} = this.context;
        const {closePreviousModal, showPreviousModal} = this.props;
        closePreviousModal();
        const qs = document.querySelector('#professional-profile');
        return ReactDOM.createPortal(
            <Modal
                closeModal={() => {
                    closeModal();
                }}
                initElement={document.activeElement}
                parentElement={document.activeElement.parentElement}
            >
                <PromotionsContent
                    isMobile={isMobile}
                    isMobileOrTablet={isMobileOrTablet}
                    showPreviousModal={showPreviousModal}
                />
            </Modal>,
            qs,
        );
    }

    render() {
        const {displayModal} = this.context;
        if (!displayModal) {
            return null;
        }
        return <>{this.renderModal()}</>;
    }
}
