import {observer} from 'mobx-react';
import React from 'react';

import autobind from 'common/decorators/autobind.js';
import Icon from 'core/components/Icon.js';
import {PromotionsStoreContext} from 'core/stores/RootStore.js';

export default
@observer
class PromotionsQuestionnaire extends React.Component {
    static contextType = PromotionsStoreContext;

    @autobind
    selectAnswer(answer, questionIndex) {
        this.context.selectAnswer(answer, questionIndex);
    }

    renderQuestion(question, questionIndex) {
        const {selectedAnswers} = this.context;

        // if the previous question has not been answered, do not render
        if (selectedAnswers.length < questionIndex) {
            return null;
        }

        return (
            <section
                className="question bounce-down"
                id={`question-${questionIndex}`}
                key={`question-${questionIndex}`}
            >
                <label>{question.question}</label>
                <ul className="options">
                    {question.answers.map((answer, answerIndex) =>
                        this.renderAnswer(answer, answerIndex, questionIndex),
                    )}
                </ul>
            </section>
        );
    }

    renderAnswer(answer, answerIndex, questionIndex) {
        const {selectedAnswers} = this.context;

        let isSelected = false;
        if (
            selectedAnswers.length > questionIndex &&
            selectedAnswers[questionIndex].answer === answer.name
        ) {
            isSelected = true;
        }

        return (
            <li key={`question-${questionIndex}-answer-${answerIndex}`}>
                <label>
                    <input
                        checked={isSelected}
                        name={`question-${questionIndex}`}
                        onChange={() =>
                            this.selectAnswer(answer, questionIndex)
                        }
                        type="radio"
                    />
                    <Icon name="radio" />
                    <p>{answer.name}</p>
                </label>
            </li>
        );
    }

    renderQuestions() {
        const {
            promotedHealthFundSelected,
            questions,
            specialties,
        } = this.context;

        if (promotedHealthFundSelected || specialties.length > 1) {
            return questions.map((question, questionIndex) =>
                this.renderQuestion(question, questionIndex),
            );
        }

        return null;
    }

    render() {
        return this.renderQuestions();
    }
}
