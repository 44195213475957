import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import autobind from 'common/decorators/autobind.js';
import Button from 'core/components/Button.js';
import Icon from 'core/components/Icon.js';
import Notification from 'core/components/Notification.js';
import {PromotionsStoreContext} from 'core/stores/RootStore.js';

import PromotionsBannerAd from 'promotions/components/PromotionsBannerAd.js';
import PromotionsQuestionnaire from 'promotions/components/PromotionsQuestionnaire.js';
import PromotionsContactForm from 'promotions/components/PromotionsContactForm.js';
import PromotedProfiles from 'promotions/components/PromotedProfiles.js';
import {MODAL_TYPE_BANNERADS} from 'promotions/stores/PromotionsStore.js';

export default
@observer
class PromotionsContent extends React.Component {
    static contextType = PromotionsStoreContext;

    static propTypes = {
        isMobile: PropTypes.bool,
        isMobileOrTablet: PropTypes.bool,
        showPreviousModal: PropTypes.func.isRequired,
    };

    static defaultProps = {
        isMobile: false,
        isMobileOrTablet: false,
    };

    constructor() {
        super();
        this.promotedProfile = React.createRef();
    }

    @autobind
    updateTrackedComponents(component) {
        this.setState({[component]: false});
    }

    renderSorryMessage() {
        const {hasNoPromotions, hideSorryMessage} = this.context;

        if (hideSorryMessage) {
            return null;
        }

        const sorryText = hasNoPromotions
            ? 'Sorry. Based on the information you have provided, we are unable to offer any options to see a specialist quickly through HealthShare at this time.'
            : "Sorry. Based on your selected specialty, your health fund isn't offering any no-cost treatment advice options through HealthShare at this time.";

        return (
            <Notification
                customClassName={'bounce-down'}
                text={sorryText}
                type={'neutral'}
            />
        );
    }

    renderFooter() {
        const {
            bannerCopy,
            displayPromotedProfiles,
            hasNoPromotions,
            selectedPromotion,
            selectedSpecialty,
            specialties,
            specialtyBannerAds,
            isMobile,
            isMobileOrTablet,
            rootStore: {
                paramStore: {isClient, isReferrals, isStaff},
                healthFundStore: {healthFund},
            },
        } = this.context;

        if (selectedPromotion) {
            return <PromotionsContactForm />;
        } else if (displayPromotedProfiles) {
            const profiles = specialtyBannerAds.map((ad, idx) => {
                return {
                    id: ad.id,
                    avatar: ad.searchAvatar || ad.avatar,
                    displayName: ad.headline,
                    profileUrl: ad.url,
                    specialties: ad.specialties,
                    initials: ad.initials,
                };
            });
            const hasMedicalSpecialty = !!specialties?.find(
                (sp) => sp.isMedical,
            );
            return (
                <>
                    {this.renderSorryMessage()}
                    {specialtyBannerAds.length > 0 && (
                        <>
                            <strong className="profile-header">
                                {bannerCopy}
                            </strong>
                            {specialtyBannerAds.length === 1 && (
                                <PromotionsBannerAd
                                    {...specialtyBannerAds[0]}
                                    displayPhoneNumber={false}
                                    hasMedicalSpecialty={hasMedicalSpecialty}
                                    healthFund={healthFund}
                                    isClient={isClient}
                                    isMobileOrTablet={isMobileOrTablet}
                                    isReferrals={isReferrals}
                                    isStaff={isStaff}
                                    ref={this.promotedProfile}
                                />
                            )}
                            {specialtyBannerAds.length > 1 && (
                                <PromotedProfiles
                                    customClass="promoted-profiles"
                                    gapSchemeResultsCount={profiles.length}
                                    healthFundId={healthFund?.id}
                                    isMobile={isMobile}
                                    isMobileOrTablet={isMobileOrTablet}
                                    profiles={profiles}
                                    showLinktoMorePractitioners={false}
                                    specialtyId={selectedSpecialty}
                                />
                            )}
                        </>
                    )}
                </>
            );
        }
    }

    @autobind
    handleBackButton() {
        const {showPreviousModal} = this.props;
        const {closeModal} = this.context;
        showPreviousModal();
        closeModal();
    }

    renderNotification() {
        const {formNotification} = this.context;
        const isSuccess = formNotification === 'success';

        return (
            <Notification
                buttonText={
                    isSuccess ? 'Return to profile' : 'Review submission'
                }
                customClassName={'bounce-down'}
                onButtonClick={this.handleNotificationButton}
                text={
                    isSuccess
                        ? 'Thanks for submitting a callback request. You will be contacted shortly to discuss what options are available to you. '
                        : 'We are experiencing network difficulties. Please try again. '
                }
                type={formNotification}
            />
        );
    }

    @autobind
    handleNotificationButton() {
        const {closeModal, formNotification} = this.context;
        const isSuccess = formNotification === 'success';
        this.context.updateStore({
            formNotification: null,
        });
        if (isSuccess) {
            closeModal();
        }
    }

    render() {
        const {
            closeModal,
            formNotification,
            hasBackButton,
            modalType,
            titleCopy,
        } = this.context;
        const displayBackButton = hasBackButton && !formNotification;
        return (
            <section className="promotions-modal">
                {displayBackButton && (
                    <Button
                        action={this.handleBackButton}
                        customClass="previous"
                        text={<Icon name="arrowInverse" />}
                    />
                )}
                <Button
                    action={() => {
                        closeModal();
                    }}
                    customClass="dismiss"
                    text={<Icon name="close" />}
                />
                <h1>{titleCopy}</h1>
                {modalType === MODAL_TYPE_BANNERADS && (
                    <h2>{'See what options are available to you.'}</h2>
                )}
                {formNotification ? (
                    this.renderNotification()
                ) : (
                    <>
                        <PromotionsQuestionnaire />
                        {this.renderFooter()}
                    </>
                )}
            </section>
        );
    }
}
