import {observer} from 'mobx-react';
import React from 'react';

import autobind from 'common/decorators/autobind.js';
import {
    PROMOTION_TREATMENT_DECISION_SUPPORT,
    PROMOTION_SECOND_OPINION,
} from 'core/promotions.js';
import Button from 'core/components/Button.js';
import ReCaptcha from 'core/components/ReCaptcha';
import {PromotionsStoreContext} from 'core/stores/RootStore.js';
import events from 'events.js';
import http from 'http.js';
import {isValidAustralianPhoneNumber} from 'utils/validation.js';

export default
@observer
class PromotionsContactForm extends React.Component {
    static contextType = PromotionsStoreContext;

    constructor(props) {
        super(props);
        this.state = {
            captchaToken: null,
            submitting: false,
            showFullNameError: false,
            showPhoneNumberError: false,
        };
        this.formRef = React.createRef();
    }

    componentDidMount() {
        events.listen(document.body, 'click', this.handleClick);
        this.formRef.current.scrollIntoView();
    }

    componentWillUnmount() {
        events.unlisten(document.body, 'click', this.handleClick);
    }

    get isValidFullName() {
        return !!this.context.formFullName;
    }

    get isValidPhoneNumber() {
        return isValidAustralianPhoneNumber(this.context.formPhoneNumber);
    }

    get isValidForm() {
        return this.isValidPhoneNumber && this.isValidFullName;
    }

    @autobind
    handleFullNameChange(event) {
        this.context.updateStore({
            formFullName: event.currentTarget.value,
        });
    }

    @autobind
    handlePhoneNumberChange(event) {
        this.context.updateStore({
            formPhoneNumber: event.currentTarget.value,
        });
    }

    @autobind
    handleClick() {
        this.setState({
            showFullNameError: false,
            showPhoneNumberError: false,
        });
    }

    @autobind
    async handleFormSubmit(event) {
        event.preventDefault();
        if (!this.isValidForm) {
            this.setValidationMessages();
            return null;
        }
        const {captchaToken} = this.state;
        const {
            chosenSpecialty,
            formFullName,
            formPhoneNumber,
            hasSeenASpecialist,
            selectedPromotion,
            jointPainAnswer,
            trackJointPainQuestion,
            rootStore: {
                healthFundStore: {healthFund},
            },
        } = this.context;

        const url = '/api/promotions/v1/email-teladoc/';
        const data = {
            'captcha_token': captchaToken,
            'full_name': formFullName,
            'has_consent': true,
            'has_seen_a_specialist': hasSeenASpecialist,
            'health_fund': healthFund?.name,
            'phone_number': formPhoneNumber,
            'promotion': selectedPromotion.id,
            'specialty': chosenSpecialty,
            'joint_pain': jointPainAnswer?.isCorrect,
        };

        this.setState({submitting: true});
        try {
            await http.post({url, data});
            trackJointPainQuestion();
            this.context.updateStore({formNotification: 'success'});
        } catch (error) {
            this.context.updateStore({formNotification: 'error'});
            throw new Error(error);
        } finally {
            this.setState({submitting: false});
        }
    }

    setValidationMessages() {
        this.setState({
            showFullNameError: !this.isValidFullName,
            showPhoneNumberError: !this.isValidPhoneNumber,
        });
    }

    @autobind
    setCaptchaToken(captchaToken) {
        this.setState({captchaToken});
    }

    renderRequiredMessage(message) {
        return (
            <span className="notice" role="alert">
                {message}
            </span>
        );
    }

    render() {
        const {
            formFullName,
            formPhoneNumber,
            isMobile,
            selectedPromotion,
            rootStore: {
                healthFundStore: {healthFund},
            },
        } = this.context;
        const {
            showFullNameError,
            showPhoneNumberError,
            submitting,
        } = this.state;

        return (
            <section className="form-split bounce-down" ref={this.formRef}>
                {selectedPromotion.name ===
                PROMOTION_TREATMENT_DECISION_SUPPORT ? (
                    <div>
                        <strong>{selectedPromotion.title}</strong>
                        <ul>
                            <li>{'It is a free telehealth service'}</li>
                            <li>
                                {
                                    'A leading orthopaedic specialist will review your case'
                                }
                            </li>
                            <li>
                                {
                                    'Personalised treatment options will be provided'
                                }
                            </li>
                            <li>
                                {
                                    'Expert medical advice is delivered by Best Doctors Australia'
                                }
                            </li>
                        </ul>
                        <p>
                            {
                                'By submitting your information, you consent for Best Doctors Australia to contact you about the Treatment Decision Support service.'
                            }
                        </p>
                    </div>
                ) : (
                    <>
                        <strong>{`${healthFund?.name} `}</strong>
                        {selectedPromotion.title}
                        <strong>{'free service.'}</strong>
                    </>
                )}
                <aside>
                    <p>{'Prefer to phone Best Doctors?'}</p>
                    <strong>
                        <a href={`tel:${selectedPromotion.phoneNumber}`}>
                            {selectedPromotion.phoneNumber}
                        </a>
                    </strong>
                </aside>
                <form>
                    <h3>{'Your Details'}</h3>
                    <label>
                        {showFullNameError &&
                            this.renderRequiredMessage(
                                'Please provide your Name',
                            )}
                        {'Full name:'}{' '}
                        <input
                            autoFocus={!isMobile}
                            onChange={this.handleFullNameChange}
                            type="text"
                            value={formFullName}
                        />
                    </label>
                    <label>
                        {showPhoneNumberError &&
                            this.renderRequiredMessage(
                                'Please provide a valid phone number',
                            )}
                        {'Phone number:'}{' '}
                        <input
                            onChange={this.handlePhoneNumberChange}
                            type="tel"
                            value={formPhoneNumber}
                        />
                    </label>
                    <ReCaptcha
                        action="promotion_contact_form"
                        setCaptchaToken={this.setCaptchaToken}
                    />
                    <Button
                        action={this.handleFormSubmit}
                        customClass="cta"
                        disabled={submitting}
                        text={'Submit callback request'}
                        type="submit"
                    />
                </form>
            </section>
        );
    }
}
